import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
// @mui
import {
  Stack,
  Divider,
  Container,
  Typography,
  Grid,
  Skeleton,
  Switch,
  TextField,
  InputAdornment,
  Button,
  DialogContent,
  DialogTitle,
} from '@mui/material';
// graphQL
import { GET_PRE_BOOKING_BY_ID } from '../../graphQL/queries';
// routes
// hooks
// _mock_
// components
import Page from '../../components/Page';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { fCurrency } from 'src/utils/formatNumber';
import { fDateTime } from 'src/utils/formatTime';
import { useEffect, useState } from 'react';
import { RESPONSE_PRE_BOOKING_REQUEST } from 'src/graphQL/mutations';
import { useSnackbar } from 'notistack';
import useAuth from 'src/hooks/useAuth';
import { DialogAnimate } from 'src/components/animate';
// sections

// ----------------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
  minHeight: '100%',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

export default function PreBookingResponse() {
  const { id } = useParams();
  const { user } = useAuth();
  console.log({ user });
  const [switchChecked, setSwitchChecked] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [booking, setBooking] = useState();
  const [negotiated, setNegotiated] = useState();
  
  // graphQL connection
  const { loading, error, data, refetch } = useQuery(GET_PRE_BOOKING_BY_ID, {
    variables: {
      getPreBookingByIdId: id,
    },
  });
  const [editPreBookingRequest, { loading: loadingEdit }] = useMutation(RESPONSE_PRE_BOOKING_REQUEST);
  useEffect(() => {
    if (data) {
      setBooking(data?.getPreBookingByID);
    }
  }, [data]);
  // filter functions
  const onAccept = async () => {
    console.log('accept');
    await editPreBookingRequest({
      variables: {
        preBookingDetails: {
          acceptedDateTime: new Date().toISOString(),
          linguistId: user?.id,
          name: user?.name,
          email: user?.email,
          status: 'Accepted',
          total: booking?.total,
        },
        id: booking?._id,
      },
    }).then(() => {
      refetch();
      enqueueSnackbar('Accepted');
    });
  };
  const onRefuse = async () => {
    await editPreBookingRequest({
      variables: {
        preBookingDetails: {
          status: 'Refused',
          acceptedDateTime: new Date().toISOString(),
          linguistId: user?.id,
          name: user?.name,
          email: user?.email,
          total: booking?.total,
        },
        id: booking?._id,
      },
    }).then(() => {
      refetch();
      // setBooking((pre) => ({ ...pre, status: 'Refused' }));
      enqueueSnackbar('Refused', { variant: 'error' });
    });
  };
  const onNegotiate = async () => {
    if (!negotiated || negotiated === 0) {
      enqueueSnackbar('Add negotiated total', { variant: 'error' });
    } else {
      await editPreBookingRequest({
        variables: {
          preBookingDetails: {
            status: 'Negotiated',
            acceptedDateTime: new Date().toISOString(),
            linguistId: user?.id,
            name: user?.name,
            email: user?.email,
            total: negotiated,
          },
          id: booking?._id,
        },
      }).then(() => {
        refetch();
        enqueueSnackbar('Negotiated', { variant: 'error' });
      });
    }
  };
  if (error) return `Error! ${error.message}`;
  if (loading) return <Skeleton height={380} width={'100%'} />;
  return (
    <Page title="Linguist Pre-booking Request">
      <RootStyle>
        <Container>
          <Typography variant="h3" align="center" paragraph>
            New Opportunity - Interpretation Project{' '}
          </Typography>
          <Typography align="center" sx={{ color: 'text.secondary' }}>
            Let us know if you're interested!
          </Typography>
          {booking?.status === 'Pending' ? (
            <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
              <Grid container sx={{ p: 3, maxWidth: 600 }} spacing={4} justifyContent="center">
                <Grid align="center" item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
                    Event Start Date
                  </Typography>
                  <Typography variant="body2"> {fDateTime(booking?.startDateTime)}</Typography>
                </Grid>
                <Grid align="center" item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
                    Event End Date
                  </Typography>
                  <Typography variant="body2">{fDateTime(booking?.endDateTime)}</Typography>
                </Grid>
                <Grid align="center" item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
                    Source Language
                  </Typography>
                  <Typography variant="body2">{booking?.sourceLanguage}</Typography>
                </Grid>
                <Grid align="center" item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
                    Target Language
                  </Typography>
                  <Typography variant="body2">{booking?.targetLanguage}</Typography>
                </Grid>
                <Grid align="center" item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
                    Location
                  </Typography>
                  <Typography variant="body2"> {booking?.address}</Typography>
                </Grid>

                <Grid align="center" item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
                    Total Gross Amount
                  </Typography>
                  <Typography variant="body2"> {fCurrency(booking?.total)}</Typography>
                </Grid>

                <Divider style={{ width: '60%' }} sx={{ mt: 2.5, mb: 1.5 }} />
                <Grid item xs={12} md={4} lg={8}>
                  <>
                    <Typography sx={{ float: 'left', color: 'text.secondary' }} variant="body2">
                      Not happy with the rate proposed? Negotiate!
                    </Typography>
                    <Switch
                      sx={{ float: 'right' }}
                      onChange={() => setSwitchChecked(!switchChecked)}
                      checked={switchChecked}
                    />
                  </>
                </Grid>
                <Grid item xs={8} md={4} lg={8}>
                  {switchChecked === true ? (
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 1 }} mt={2}>
                      <TextField
                        type="number"
                        name="negotiatedTotal"
                        label="Negotiated Total"
                        onChange={(event) => setNegotiated(event.target.value)}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">€</InputAdornment>,
                        }}
                        sx={{ maxWidth: { md: 240 } }}
                      />
                      <LoadingButton
                        onClick={() => onNegotiate()}
                        variant="contained"
                        size="large"
                        loading={loadingEdit}
                      >
                        {'Negotiate'}
                      </LoadingButton>
                    </Stack>
                  ) : (
                    <Stack
                      justifyContent="flex-end"
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={{ xs: 1, sm: 2, md: 1 }}
                      mt={2}
                    >
                      <LoadingButton
                        loading={loadingEdit}
                        variant="contained"
                        size="large"
                        color="error"
                        onClick={() => onRefuse()}
                      >
                        Maybe next time!
                      </LoadingButton>
                      <LoadingButton
                        variant="contained"
                        sx={{ color: 'white' }}
                        size="large"
                        color="success"
                        loading={loadingEdit}
                        onClick={() => onAccept()}
                      >
                        Apply
                      </LoadingButton>
                    </Stack>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ) : booking?.status === 'Accepted' ? (
            <Stack display="flex" alignItems="center" justifyContent="center" pt={4}>
              <Typography variant="h6" align="center" paragraph mt={5} maxWidth={430}>
                THANK YOU FOR SUBMITTING YOUR RESPONSE; WE APPRECIATE YOUR INTEREST AND WILL CONTACT YOU IF WE DECIDE TO
                MOVE FORWARD{' '}
              </Typography>
            </Stack>
          ) : (
            <Stack display="flex" alignItems="center" justifyContent="center" pt={4}>
              <Typography variant="h6" align="center" paragraph mt={5} maxWidth={430}>
                THANK YOU FOR YOUR RESPONSE; WE RESPECT YOUR DECISION AND HOPE TO WORK TOGETHER IN THE FUTURE.
              </Typography>
            </Stack>
          )}
        </Container>
      </RootStyle>

    </Page>
  );
}

// ----------------------------------------------------------------------
