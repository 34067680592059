import { useSelector } from 'react-redux';
import styles from '../styles/Introduction.module.css';
import { Clients } from '../../../../_mock';

const Introduction = () => {
  const { language, details } = useSelector((state) => state.proposal);

  const { bookedBy, accountManager, companyNameAndId, addOns, industry, clients } = details;

  const avatar = accountManager?.avatarUrl || '';
  if (language === 'English') {
    return (
      <>
        <div className={styles.desktop6}>
          <div className={styles.desktop6Item} />
          {/* <div className={styles.div}>02</div> */}
          <div className={styles.introductionLetter}>INTRODUCTORY LETTER</div>
          <div className={styles.text3543Parent}>
            <img alt="" src="/translationAssets/desktop6/group-1000001719.svg" />
          </div>
          {!addOns?.friendly ? (
            <div className={styles.dearTestThanksContainer}>
              <p className={styles.dearTest}>
                <span>Dear {bookedBy ? bookedBy?.firstName : '__'},</span>
              </p>
              <p className={styles.dearTest}>
                <span>&nbsp;</span>
              </p>
              <p className={styles.dearTest}>
                <span>
                  Thank you for choosing to partner with LingoYou. For more information regarding our reality, please
                  browse the pages of this presentation dedicated to {companyNameAndId?.name}.
                </span>
              </p>
              <p className={styles.dearTest}>
                <span>&nbsp;</span>
              </p>
              <p className={styles.dearTest}>
                <span>
                  I'm happy to share with you all our company information and processes hoping to collaborate with you
                  in the near future.
                </span>
              </p>
              <p className={styles.dearTest}>
                <span>&nbsp;</span>
              </p>
              <p className={styles.dearTest}>
                <span>{`Our considerable experience in the sector, together with our flexibility and precision, make LingoYou the best choice for important companies such as `}</span>

                <b className={styles.skyGoogleBmw}>
                  {clients
                    ? clients?.map((client) => client?.name).join(', ')
                    : `Sky, Google, Ministero dell'Interno, Telepass, Ferrari, Mercedes-Benz, Honda, Alfasigma, Takeda, Clarins, MAC Cosmetics, Unicredit, Banca d'Italia, Gucci, Dior, Gruppo Angelini
             `}{' '}
                </b>

                <span>
                  {` and many others, for which we have provided translation and interpreting services and organized on-site and online conferences.`}
                </span>
              </p>
              <p className={styles.dearTest}>
                <span>&nbsp;</span>
              </p>
              <p className={styles.dearTest}>
                <span>
                  Here at LingoYou each client is unique and special, and we would be particularly keen to begin a long
                  and fruitful relationship with {companyNameAndId?.name}.
                </span>
              </p>
              <p className={styles.dearTest}>
                <span>&nbsp;</span>
              </p>
              <p className={styles.dearTest}>
                <span>
                  Quality has always been our strong point. For this, we select translators registered with one of the
                  major linguist associations (AITI, AssoInterpreti, AIIC, etc.), with at least five years of work
                  experience in your sector.
                </span>
              </p>
              <p className={styles.dearTest}>
                <span>&nbsp;</span>
              </p>
              <p className={styles.dearTest}>
                <span>We will contact you by phone to agree on the next steps together.</span>
              </p>
              <p className={styles.dearTest}>
                <span>&nbsp;</span>
              </p>
              <p className={styles.bestRegards}>
                <span>Best regards,</span>
              </p>
            </div>
          ) : (
            <div className={styles.dearTestThanksContainer}>
              <p className={styles.dearTest}>
                <span>Dear {bookedBy ? bookedBy?.firstName : '__'},</span>
              </p>
              <p className={styles.dearTest}>
                <span>&nbsp;</span>
              </p>
              <p className={styles.dearTest}>
                <span>
                  Thank you for choosing to collaborate with LingoYou. For more information regarding our reality,
                  please browse the pages of this presentation that we have dedicated to {companyNameAndId?.name}.
                </span>
              </p>
              <p className={styles.dearTest}>
                <span>&nbsp;</span>
              </p>
              <p className={styles.dearTest}>
                <span>As requested, we will send you our quote below.</span>
              </p>
              <p className={styles.dearTest}>
                <span>&nbsp;</span>
              </p>
              <p className={styles.dearTest}>
                <span>
                  Our considerable experience in the sector, together with our flexibility and precision, make LingoYou
                  the best choice for important companies such as{' '}
                </span>

                <b className={styles.skyGoogleBmw}>
                  Sky, Google, Ministry of the Interior, Telepass, Ferrari, Mercedes-Benz, Honda, Alfasigma, Takeda,
                  Clarins, MAC Cosmetics , Unicredit, Bank of Italy, Gucci, Dior, Angelini Group.
                </b>
                <span>
                  &nbsp; and many others, for which we have provided translation and interpreting services and organized
                  on-site and online conferences.
                </span>
              </p>
              <p className={styles.dearTest}>
                <span>&nbsp;</span>
              </p>
              <p className={styles.dearTest}>
                <span>
                  Here at LingoYou every customer is unique and special, and we would particularly like to start a long
                  and fruitful collaboration with {companyNameAndId?.name}.
                </span>
              </p>
              <p className={styles.dearTest}>
                <span>&nbsp;</span>
              </p>
              <p className={styles.dearTest}>
                <span>
                  Quality has always been our strong point. For this reason, we select translators registered with one
                  of the major linguist associations (AITI, AssoInterpreti, AIIC, etc.), with at least five years of
                  work experience in your sector.
                </span>
              </p>
              <p className={styles.dearTest}>
                <span>&nbsp;</span>
              </p>
              <p className={styles.dearTest}>
                <span>We will contact you by phone to agree on the next steps together.</span>
              </p>
              <p className={styles.dearTest}>
                <span>&nbsp;</span>
              </p>
              <p className={styles.bestRegards}>
                <span>See you soon,</span>
              </p>
            </div>
          )}

          <div className={styles.desktop6Child}>
            <div id="footer_AM" className={styles.ellipseParent}>
              {accountManager && accountManager.name === 'Giuseppe Gagliano' && (
                <img
                  id="avatar"
                  className={styles.frameChild}
                  alt=""
                  src="/translationAssets/desktop7/GiuseppeGagliano.webp"
                />
              )}
              {accountManager && accountManager.name === 'Alessia Tortorici' && (
                <img
                  id="avatar"
                  className={styles.frameChild}
                  alt=""
                  src="/translationAssets/desktop7/AlessiaTortorici.webp"
                />
              )}
              {accountManager && accountManager.name === 'Chiara Moschitta' && (
                <img
                  id="avatar"
                  className={styles.frameChild}
                  alt=""
                  src="/translationAssets/desktop7/ChiaraMoschitta.webp"
                />
              )}
              {accountManager && accountManager.name === 'Giulia Caravasso' && (
                <img
                  id="avatar"
                  className={styles.frameChild}
                  alt=""
                  src="/translationAssets/desktop7/GiuliaCaravasso.webp"
                />
              )}

              <img className={styles.sign1Icon} alt="" src="/translationAssets/desktop6/sign-1@2x.png" />
              <b className={styles.missFedericaCaramella}>{accountManager ? accountManager.name : '__'}</b>
              <i className={styles.accountManager}>{accountManager?.jobTitle} – LingoYou</i>
              <div className={styles.languagesParent}>
                <i className={styles.accountManager}>Languages:</i>
                <div className={styles.chinaParent}>
                  {accountManager &&
                    accountManager?.language &&
                    accountManager?.language?.map((language) => {
                      if (language.name === 'Chinese') {
                        return (
                          <div className={styles.china}>
                            <img className={styles.artworkIcon} alt="" src="/assets/desktop6/artwork.svg" />
                          </div>
                        );
                      } else if (language.name === 'Italian') {
                        return (
                          <div className={styles.china}>
                            <img className={styles.artworkIcon} alt="" src="/assets/desktop6/artwork1.svg" />
                          </div>
                        );
                      } else if (language.name === 'English') {
                        return (
                          <div className={styles.china}>
                            <img className={styles.artworkIcon} alt="" src="/assets/desktop6/artwork2.svg" />
                          </div>
                        );
                      } else if (language.name === 'Spanish') {
                        return (
                          <div className={styles.china}>
                            <img
                              className={styles.artworkIcon}
                              style={{ width: '30px' }}
                              alt=""
                              src="/assets/desktop6/artwork.png"
                            />
                          </div>
                        );
                      }
                    })}
                </div>
              </div>
              <i className={styles.accountManager}>Email: info@lingoyou.com</i>
              <i className={styles.accountManager}>Phone: +39 0282957101</i>
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className={styles.desktop6}>
        <div className={styles.desktop6Item} />
        {/* <div className={styles.div}>02</div> */}
        <div className={styles.introductionLetter}>Lettera Introduttiva</div>
        <div className={styles.text3543Parent}>
          <img alt="" src="/translationAssets/desktop6/group-1000001719.svg" />
        </div>
        {!addOns?.friendly ? (
          <div className={styles.dearTestThanksContainer}>
            <p className={styles.dearTest}>
              <span>Gentile {bookedBy ? bookedBy?.firstName : '__'},</span>
            </p>
            <p className={styles.dearTest}>
              <span>&nbsp;</span>
            </p>
            <p className={styles.dearTest}>
              <span>
                La ringraziamo per aver scelto di collaborare con LingoYou. Per avere maggiori informazioni riguardanti
                la nostra realtà, La preghiamo di sfogliare le pagine di questa presentazione che abbiamo dedicato a{' '}
                {companyNameAndId?.name}.
              </span>
            </p>
            <p className={styles.dearTest}>
              <span>&nbsp;</span>
            </p>
            <p className={styles.dearTest}>
              <span>
                Sono felice di condividere le nostre informazioni e processi aziendali augurandomi di collaborare presto
                con Voi.
              </span>
            </p>
            <p className={styles.dearTest}>
              <span>&nbsp;</span>
            </p>
            <p className={styles.dearTest}>
              <span>{`La nostra notevole esperienza nel settore, insieme alla nostra flessibilità e precisione, rendono LingoYou la migliore scelta per importanti aziende come `}</span>
              <b className={styles.skyGoogleBmw}>
                {clients
                  ? clients?.map((client) => client?.name).join(', ')
                  : `Sky, Google, Ministero dell'Interno, Telepass, Ferrari, Mercedes-Benz, Honda, Alfasigma, Takeda, Clarins, MAC Cosmetics, Unicredit, Banca d'Italia, Gucci, Dior, Gruppo Angelini
             `}
              </b>{' '}
              <span>
                e molte altre, per le quali abbiamo fornito servizi di traduzione e di interpretariato e organizzato
                conferenze on-site e online.{' '}
              </span>
            </p>
            <p className={styles.dearTest}>
              <span>&nbsp;</span>
            </p>
            <p className={styles.dearTest}>
              <span>
                Qui a LingoYou ogni cliente è unico e speciale, e ci terremmo particolarmente ad iniziare una lunga e
                proficua collaborazione con {companyNameAndId?.name}.
              </span>
            </p>
            <p className={styles.dearTest}>
              <span>&nbsp;</span>
            </p>
            <p className={styles.dearTest}>
              <span>
                La qualità è da sempre il nostro punto di forza. Per questo, selezioniamo traduttori iscritti ad una
                delle maggiori associazioni di categoria (AITI, AssoInterpreti, AIIC, etc.), con almeno cinque anni di
                esperienza lavorativa nel Vostro settore.
              </span>
            </p>
            <p className={styles.dearTest}>
              <span>&nbsp;</span>
            </p>
            <p className={styles.dearTest}>
              <span>La contatteremo telefonicamente per concordare insieme i prossimi step.</span>
            </p>
            <p className={styles.dearTest}>
              <span>&nbsp;</span>
            </p>
            <p className={styles.bestRegards}>
              <span>Cordiali saluti,</span>
            </p>
          </div>
        ) : (
          <div className={styles.dearTestThanksContainer}>
            <p className={styles.dearTest}>
              <span>Gentile {bookedBy ? bookedBy?.firstName : '__'},</span>
            </p>
            <p className={styles.dearTest}>
              <span>&nbsp;</span>
            </p>
            <p className={styles.dearTest}>
              <span>
                Ti ringraziamo per aver scelto di collaborare con LingoYou. Per avere maggiori informazioni riguardanti
                la nostra realtà, ti preghiamo di sfogliare le pagine di questa presentazione che abbiamo dedicato a{' '}
                {companyNameAndId?.name}.
              </span>
            </p>
            <p className={styles.dearTest}>
              <span>&nbsp;</span>
            </p>
            <p className={styles.dearTest}>
              <span>Come richiesto, ti inviamo di seguito il nostro preventivo.</span>
            </p>
            <p className={styles.dearTest}>
              <span>&nbsp;</span>
            </p>
            <p className={styles.dearTest}>
              <span>
                La nostra notevole esperienza nel settore, insieme alla nostra flessibilità e precisione, rendono
                LingoYou la migliore scelta per importanti aziende come{' '}
              </span>

              <b className={styles.skyGoogleBmw}>
                Sky, Google, Ministero dell'Interno, Telepass, Ferrari, Mercedes-Benz, Honda, Alfasigma, Takeda,
                Clarins, MAC Cosmetics, Unicredit, Banca d'Italia, Gucci, Dior, Gruppo Angelini
              </b>
              <span>
                {' '}
                &nbsp; e molte altre, per le quali abbiamo fornito servizi di traduzione e di interpretariato e
                organizzato conferenze on-site e online.{' '}
              </span>
            </p>
            <p className={styles.dearTest}>
              <span>&nbsp;</span>
            </p>
            <p className={styles.dearTest}>
              <span>
                Qui a LingoYou ogni cliente è unico e speciale, e ci terremmo particolarmente ad iniziare una lunga e
                proficua collaborazione con {companyNameAndId?.name}.
              </span>
            </p>
            <p className={styles.dearTest}>
              <span>&nbsp;</span>
            </p>
            <p className={styles.dearTest}>
              <span>
                La qualità è da sempre il nostro punto di forza. Per questo, selezioniamo traduttori iscritti ad una
                delle maggiori associazioni di categoria (AITI, AssoInterpreti, AIIC, etc.), con almeno cinque anni di
                esperienza lavorativa nel Vostro settore.
              </span>
            </p>
            <p className={styles.dearTest}>
              <span>&nbsp;</span>
            </p>
            <p className={styles.dearTest}>
              <span>Ti contatteremo telefonicamente per concordare insieme i prossimi step.</span>
            </p>
            <p className={styles.dearTest}>
              <span>&nbsp;</span>
            </p>
            <p className={styles.bestRegards}>
              <span>A presto,</span>
            </p>
          </div>
        )}
        <div className={styles.desktop6Child}>
          <div id="footer_AM" className={styles.ellipseParent}>
            {accountManager && accountManager.name === 'Giuseppe Gagliano' && (
              <img
                id="avatar"
                className={styles.frameChild}
                alt=""
                src="/translationAssets/desktop7/GiuseppeGagliano.webp"
              />
            )}
            {accountManager && accountManager.name === 'Alessia Tortorici' && (
              <img
                id="avatar"
                className={styles.frameChild}
                alt=""
                src="/translationAssets/desktop7/AlessiaTortorici.webp"
              />
            )}
            {accountManager && accountManager.name === 'Chiara Moschitta' && (
              <img
                id="avatar"
                className={styles.frameChild}
                alt=""
                src="/translationAssets/desktop7/ChiaraMoschitta.webp"
              />
            )}
            {accountManager && accountManager.name === 'Giulia Caravasso' && (
              <img
                id="avatar"
                className={styles.frameChild}
                alt=""
                src="/translationAssets/desktop7/GiuliaCaravasso.webp"
              />
            )}

            <img className={styles.sign1Icon} alt="" src="/translationAssets/desktop6/sign-1@2x.png" />
            <b className={styles.missFedericaCaramella}>{accountManager ? accountManager.name : '__'}</b>
            <i className={styles.accountManager}>{accountManager?.jobTitle} – LingoYou</i>
            <div className={styles.languagesParent}>
              <i className={styles.accountManager}>Lingue:</i>
              <div className={styles.chinaParent}>
                {accountManager &&
                  accountManager?.language &&
                  accountManager?.language?.map((language) => {
                    if (language.name === 'Chinese') {
                      return (
                        <div className={styles.china}>
                          <img className={styles.artworkIcon} alt="" src="/assets/desktop6/artwork.svg" />
                        </div>
                      );
                    } else if (language.name === 'Italian') {
                      return (
                        <div className={styles.china}>
                          <img className={styles.artworkIcon} alt="" src="/assets/desktop6/artwork1.svg" />
                        </div>
                      );
                    } else if (language.name === 'English') {
                      return (
                        <div className={styles.china}>
                          <img className={styles.artworkIcon} alt="" src="/assets/desktop6/artwork2.svg" />
                        </div>
                      );
                    } else if (language.name === 'Spanish') {
                      return (
                        <div className={styles.china}>
                          <img
                            className={styles.artworkIcon}
                            style={{ width: '30px' }}
                            alt=""
                            src="/assets/desktop6/artwork.png"
                          />
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
            <i className={styles.accountManager}>Email: info@lingoyou.com</i>
            <i className={styles.accountManager}>Phone: +39 0282957101</i>
          </div>
        </div>
      </div>
    </>
  );
};

export default Introduction;
