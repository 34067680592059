import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../globalStyles';
import { Clients } from 'src/_mock';

const Introduction = ({ language, bookedBy, accountManager, companyNameAndId, addOns, industry, clients }) => {
  return (
    <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
      <View style={{ ...styles.header, marginBottom: 0, width: '94%', marginHorizontal: 'auto' }}>
        <View style={{ ...styles.headerItem }}>
          <Text style={styles.headerItemText}>
            {language === 'English' ? `Introductory Letter` : `Lettera Introduttiva`}
          </Text>
        </View>
        <View>
          <Image style={{ ...styles.headerRightImg }} src="/translationAssets/desktop6/lingoyou.png" />
        </View>
        <View style={styles.headerItem} />
      </View>
      {!addOns?.friendly ? (
        <View style={styles.introductionBody}>
          <Text style={styles.introductionParaBody}>
            {language === 'English'
              ? `Dear ${bookedBy ? bookedBy?.firstName : '__'},`
              : `Gentile ${bookedBy ? bookedBy?.firstName : '__'},`}
          </Text>
          <Text style={styles.introductionParaBody}>
            {language === 'English'
              ? `Thank you for choosing to partner with LingoYou. For more information regarding our reality, please browse the pages of this presentation dedicated to ${companyNameAndId?.name}.`
              : `La ringraziamo per aver scelto di collaborare con LingoYou. Per avere maggiori informazioni riguardanti la nostra realtà, La preghiamo di sfogliare le pagine di questa presentazione che abbiamo dedicato a ${companyNameAndId?.name}.`}
          </Text>
          <Text style={styles.introductionParaBody}>
            {language === 'English'
              ? `I'm happy to share with you all our company information and processes hoping to collaborate with you in the near future.`
              : `Sono felice di condividere le nostre informazioni e processi aziendali augurandomi di collaborare presto con Voi.`}
          </Text>
          <Text style={{ ...styles.introductionParaBody }}>
            {language === 'English'
              ? `Our considerable experience in the sector, together with our flexibility and precision, make LingoYou the best choice for important companies such as  `
              : `La nostra notevole esperienza nel settore, insieme alla nostra flessibilità e precisione, rendono LingoYou la migliore scelta per importanti aziende come `}
            <Text style={{ ...styles.introductionParaBody, fontWeight: 'bold' }}>
              {clients
                ? clients?.map((client) => client?.name).join(', ')
                : `Sky, Google, Ministero dell'Interno, Telepass, Ferrari, Mercedes-Benz, Honda, Alfasigma, Takeda, Clarins, MAC Cosmetics, Unicredit, Banca d'Italia, Gucci, Dior, Gruppo Angelini
             `}
            </Text>
            <Text style={styles.introductionParaBody}>
              {language === 'English'
                ? ` and many others, for which we have provided translation and interpreting services and organized on-site and online conferences.`
                : ` e molte altre, per le quali abbiamo fornito servizi di traduzione e di interpretariato e organizzato conferenze on-site e online.`}
            </Text>
          </Text>
          <Text style={styles.introductionParaBody}>
            {language === 'English'
              ? `Here at LingoYou each client is unique and special, and we would be particularly keen to begin a long and fruitful relationship with ${companyNameAndId?.name}.`
              : ` Qui a LingoYou ogni cliente è unico e speciale, e ci terremmo particolarmente ad iniziare una lunga e proficua collaborazione con ${companyNameAndId?.name}.`}
          </Text>
          <Text style={styles.introductionParaBody}>
            {language === 'English'
              ? `Quality has always been our strong point. For this reason, we select translators registered with one of the major trade associations (AITI, AssoInterpreti, AIIC, etc.), with at least five years of work experience in your sector.`
              : `La qualità è da sempre il nostro punto di forza. Per questo, selezioniamo traduttori iscritti ad una delle maggiori associazioni di categoria (AITI, AssoInterpreti, AIIC, etc.), con almeno cinque anni di esperienza lavorativa nel Vostro settore.`}
          </Text>
          <Text style={styles.introductionParaBody}>
            {language === 'English'
              ? `We will contact you by phone to agree on the next steps together.`
              : `La contatteremo telefonicamente per concordare insieme i prossimi step.`}
          </Text>
          <Text style={styles.introductionParaBody}>
            {language === 'English' ? `Best regards,` : `Cordiali saluti,`}
          </Text>
        </View>
      ) : (
        <View style={styles.introductionBody}>
          <Text style={styles.introductionParaBody}>
            {language === 'English'
              ? `Dear ${bookedBy ? bookedBy?.firstName : '__'},`
              : `Gentile ${bookedBy ? bookedBy?.firstName : '__'},`}
          </Text>
          <Text style={styles.introductionParaBody}>
            {language === 'English'
              ? `Thank you for choosing to collaborate with LingoYou. For more information regarding our reality, please browse the pages of this presentation that we have dedicated to ${companyNameAndId?.name}.`
              : `Ti ringraziamo per aver scelto di collaborare con LingoYou. Per avere maggiori informazioni riguardanti la nostra realtà, ti preghiamo di sfogliare le pagine di questa presentazione che abbiamo dedicato a ${companyNameAndId?.name}.`}
          </Text>
          <Text style={styles.introductionParaBody}>
            {language === 'English'
              ? `As requested, we will send you our quote below.`
              : `Come richiesto, ti inviamo di seguito il nostro preventivo.`}
          </Text>
          <Text style={{ ...styles.introductionParaBody }}>
            {language === 'English'
              ? `Our considerable experience in the sector, together with our flexibility and precision, make LingoYou the best choice for major companies such as `
              : `La nostra notevole esperienza nel settore, insieme alla nostra flessibilità e precisione, rendono LingoYou la migliore scelta per importanti aziende come`}
            <Text style={{ ...styles.introductionParaBody, fontWeight: 'bold' }}>
              {` Sky, Google, Ministero dell'Interno, Telepass, Ferrari, Mercedes-Benz, Honda, Alfasigma, Takeda, Clarins, MAC Cosmetics, Unicredit, Banca d'Italia, Gucci, Dior, Gruppo Angelini`}
            </Text>
            <Text style={styles.introductionParaBody}>
              {language === 'English'
                ? `and many others, for which we have provided translation and interpreting services and organized on-site and online conferences.`
                : `e molte altre, per le quali abbiamo fornito servizi di traduzione e di interpretariato e organizzato conferenze on-site e online.`}
            </Text>
          </Text>
          <Text style={styles.introductionParaBody}>
            {language === 'English'
              ? `Here at LingoYou every customer is unique and special, and we would particularly like to start a long and fruitful collaboration with ${companyNameAndId?.name}.`
              : `Qui a LingoYou ogni cliente è unico e speciale, e ci terremmo particolarmente ad iniziare una lunga e proficua collaborazione con ${companyNameAndId?.name}.`}
          </Text>
          <Text style={styles.introductionParaBody}>
            {language === 'English'
              ? `Quality has always been our strong point. For this, we select translators registered with one of the major linguist associations (AITI, AssoInterpreti, AIIC, etc.), with at least five years of work experience in your sector.`
              : `La qualità è da sempre il nostro punto di forza. Per questo, selezioniamo traduttori iscritti ad una delle maggiori associazioni di categoria (AITI, AssoInterpreti, AIIC, etc.), con almeno cinque anni di esperienza lavorativa nel Vostro settore.`}
          </Text>
          <Text style={styles.introductionParaBody}>
            {language === 'English'
              ? `We will contact you by phone to agree on the next steps together.`
              : `Ti contatteremo telefonicamente per concordare insieme i prossimi step.`}
          </Text>
          <Text style={styles.introductionParaBody}>{language === 'English' ? `See you soon,` : `A presto,`}</Text>
        </View>
      )}
      <View style={styles.introductionFooter}>
        <View style={styles.introductionFooterImages1}>
          {accountManager && accountManager.name === 'Giuseppe Gagliano' && (
            <Image style={styles.avatarAM} src="/translationAssets/desktop7/GiuseppeGagliano.png" />
          )}
          {accountManager && accountManager.name === 'Alessia Tortorici' && (
            <Image style={styles.avatarAM} src="/translationAssets/desktop7/AlessiaTortorici.png" />
          )}
          {accountManager && accountManager.name === 'Chiara Moschitta' && (
            <Image style={styles.avatarAM} src="/translationAssets/desktop7/ChiaraMoschitta.png" />
          )}
          {accountManager && accountManager.name === 'Giulia Caravasso' && (
            <Image style={styles.avatarAM} src="/translationAssets/desktop7/GiuliaCaravasso.png" />
          )}
        </View>
        <Image style={styles.sign} src="/assets/desktop6/sign-1@2x.png" />

        <View style={styles.introductionFooterNames1}>
          <Text style={styles.introductionFooterName}>{accountManager ? accountManager.name : '__'}</Text>
        </View>
        <View style={styles.introductionFooterJobs1}>
          <Text style={{ textAlign: 'center', fontSize: '10px', fontStyle: 'italic', marginInline: 'auto' }}>
            {accountManager?.jobTitle} – LingoYou
          </Text>
          <View style={{ ...styles.introductionFooterJob }}>
            <Text style={{ fontSize: '10px', fontStyle: 'italic' }}>
              {language === 'English' ? 'Languages' : 'Lingue'}
            </Text>
            {accountManager &&
              accountManager?.language &&
              accountManager?.language?.map((language) => {
                console.log(language);
                if (language.name === 'Chinese') {
                  return <Image style={{ ...styles.artworkIcon }} src="/assets/desktop6/artwork1.png" />;
                } else if (language.name === 'Italian') {
                  return <Image style={{ ...styles.artworkIcon }} src="/assets/desktop6/artwork2.png" />;
                } else if (language.name === 'English') {
                  return <Image style={{ ...styles.artworkIcon }} src="/assets/desktop6/artwork3.png" />;
                } else if (language.name === 'Spanish') {
                  return <Image style={{ ...styles.artworkIcon }} src="/assets/desktop6/artwork.png" />;
                }
              })}
          </View>
          <Text style={{ textAlign: 'center', fontSize: '9px', fontStyle: 'italic', marginInline: 'auto' }}>
            Email: info@lingoyou.com
          </Text>
          <Text style={{ textAlign: 'center', fontSize: '9px', fontStyle: 'italic', marginInline: 'auto' }}>
            Phone: +39 0282957101
          </Text>
        </View>
      </View>
    </Page>
  );
};

export default Introduction;
