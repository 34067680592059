import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
// @mui
import {
  Stack,
  Divider,
  Container,
  Typography,
  Grid,
  Skeleton,
  TextField,
  InputAdornment,
  Switch,
  DialogTitle,
  DialogContent,
} from '@mui/material';
// graphQL
import { GET_BOOKING_BY_ID } from '../../graphQL/queries';
// routes
// hooks
// _mock_
// components
import Page from '../../components/Page';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { fCurrency } from 'src/utils/formatNumber';
import { fDateTime } from 'src/utils/formatTime';
import { useEffect, useState } from 'react';
import { EDIT_BOOKING } from 'src/graphQL/mutations';
import { useSnackbar } from 'notistack';
import { DialogAnimate } from 'src/components/animate';
import UpdateBookingRefuseStatus from './UpdateBookingRefuseStatus';
// sections

// ----------------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
  minHeight: '100%',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

export default function BookingResponse() {
  const { id } = useParams();
  const [switchChecked, setSwitchChecked] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [booking, setBooking] = useState();
  const [negotiated, setNegotiated] = useState();
  const [reason, setReason] = useState('');
  const [openRefuse, setOpenRefuse] = useState(false);
  console.log({reason})
  // graphQL connection
  const { loading, error, data, refetch } = useQuery(GET_BOOKING_BY_ID, {
    variables: {
      getPreBookingByIdId: id,
    },
  });
  const [editBookingRequest, { loading: loadingEdit }] = useMutation(EDIT_BOOKING);
  // const [allLinguistProjects, { loading: LazyLoading }] = useLazyQuery(GET_PRE_BOOKING_BY_ID);
  useEffect(() => {
    if (data) {
      setBooking(data?.getBookingByID);
    }
  }, [data]);
  // filter functions
  const onAccept = async () => {
    console.log('accept');
    await editBookingRequest({
      variables: {
        preBookingDetails: {
          status: 'Accepted',
          bookingAcceptedDateTime: new Date().toISOString(),
        },
        id: booking?._id,
      },
    }).then(() => {
      refetch();
      enqueueSnackbar('Accepted');
    });
  };
  const onRefuse = async (reason) => {
    await editBookingRequest({
      variables: {
        preBookingDetails: {
          status: 'Refused',
          reason: reason,
        },
        id: booking?._id,
      },
    }).then(() => {
      refetch();

      enqueueSnackbar('Refused', { variant: 'success' });
    });
  };
  const onNegotiate = async () => {
    if (!negotiated || negotiated === 0) {
      enqueueSnackbar('Add negotiated total', { variant: 'error' });
    } else {
      await editBookingRequest({
        variables: {
          preBookingDetails: {
            status: 'Negotiated',
            // preBookingAcceptedDateTime: new Date().toISOString(),
            negotiatedTotal: negotiated,
            reason: reason,

          },
          id: booking?._id,
        },
      }).then(() => {
        refetch();
        enqueueSnackbar('Negotiated', { variant: 'success' });
      });
    }
  };
  if (error) return `Error! ${error.message}`;
  if (loading) return <Skeleton height={380} width={'100%'} />;
  return (
    <>
      <Page title="Linguist Pre-booking Request">
        <RootStyle>
          <Container>
            <Typography variant="h3" align="center" paragraph>
              Linguist Booking Request - Interpretation Project
            </Typography>
            <Typography align="center" sx={{ color: 'text.secondary' }}>
              Let's work together
            </Typography>
            {booking?.status === 'Pending' ? (
              <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
                <Grid container sx={{ p: 3, maxWidth: 600 }} spacing={4} justifyContent="center">
                  <Grid align="center" item xs={12} sm={6} sx={{ mb: 2 }}>
                    <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
                      Event Start Date
                    </Typography>
                    <Typography variant="body2">{fDateTime(booking?.startDateTime)}</Typography>
                  </Grid>
                  <Grid align="center" item xs={12} sm={6} sx={{ mb: 2 }}>
                    <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
                      Event End Date
                    </Typography>
                    <Typography variant="body2">{fDateTime(booking?.endDateTime)}</Typography>
                  </Grid>
                  <Grid align="center" item xs={12} sm={6} sx={{ mb: 2 }}>
                    <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
                      Source Language
                    </Typography>
                    <Typography variant="body2">{booking?.sourceLanguage}</Typography>
                  </Grid>
                  <Grid align="center" item xs={12} sm={6} sx={{ mb: 2 }}>
                    <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
                      Target Language
                    </Typography>
                    <Typography variant="body2">{booking?.targetLanguage}</Typography>
                  </Grid>
                  <Grid align="center" item xs={12} sm={6} sx={{ mb: 2 }}>
                    <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
                      Location
                    </Typography>
                    <Typography variant="body2">
                      {booking?.city}, {booking?.country}
                    </Typography>
                  </Grid>
                  <Grid align="center" item xs={12} sm={6} sx={{ mb: 2 }}>
                    <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
                      Total Gross Amount
                    </Typography>
                    <Typography variant="body2">{fCurrency(booking?.total)}</Typography>
                  </Grid>

                  <Divider style={{ width: '60%' }} sx={{ mt: 2.5, mb: 1.5 }} />
                  <Grid item xs={12} md={4} lg={8}>
                    <>
                      <Typography sx={{ float: 'left', color: 'text.secondary' }} variant="body2">
                        Not happy with the rate proposed? Negotiate!
                      </Typography>
                      <Switch
                        sx={{ float: 'right' }}
                        onChange={() => setSwitchChecked(!switchChecked)}
                        checked={switchChecked}
                      />
                    </>
                  </Grid>
                  <Grid item xs={8} md={4} lg={8}>
                    {switchChecked === true ? (
                      <Stack direction={{ xs: 'column' }} spacing={{ xs: 1, sm: 2, md: 1 }} mt={2}>
                        <Stack direction={{ xs: 'column' }} spacing={{ xs: 1, sm: 2, md: 1 }} mt={2}>
                          <TextField
                            type="number"
                            name="negotiatedTotal"
                            label="Negotiated Total"
                            onChange={(event) => setNegotiated(event.target.value)}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">€</InputAdornment>,
                            }}
                            sx={{ maxWidth: { md: 320 } }}
                          />
                          <TextField
                            type="string"
                            name="reason"
                            label="Reason"
                            onChange={(event) => setReason(event.target.value)}
                            multiline
                            minRows={2}
                            sx={{ maxWidth: { md: 320 } }}
                          />
                        </Stack>

                        <LoadingButton
                          onClick={() => onNegotiate()}
                          variant="contained"
                          size="large"
                          loading={loadingEdit}
                          sx={{ maxWidth: { md: 320 } }}

                        >
                          {'Negotiate'}
                        </LoadingButton>
                      </Stack>
                    ) : (
                      <Stack
                        justifyContent="flex-end"
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 1, sm: 2, md: 1 }}
                        mt={2}
                      >
                        <LoadingButton
                          // loading={loadingEdit}
                          variant="contained"
                          size="large"
                          color="error"
                          onClick={() => setOpenRefuse(true)}
                        >
                          Refuse
                        </LoadingButton>
                        <LoadingButton
                          variant="contained"
                          sx={{ color: 'white' }}
                          size="large"
                          color="success"
                          loading={loadingEdit}
                          onClick={() => onAccept()}
                        >
                          Accept
                        </LoadingButton>
                      </Stack>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ) : booking?.status === 'Declined' ? (
              <Typography variant="h6" align="center" color="error.main" paragraph mt={5} px={16}>
                Thanks you for your interest. This project has been already allocated to other interpreter{' '}
              </Typography>
            ) : (
              <Typography variant="h5" align="center" paragraph mt={5}>
                THANK YOU FOR SUBMITTING YOUR RESPONSE. OUR TEAM WILL GET BACK TO YOU SOON.
              </Typography>
            )}
          </Container>
        </RootStyle>
      </Page>

      <DialogAnimate sx={{ maxWidth: 220 }} open={openRefuse} onClose={() => setOpenRefuse(false)}>
        <DialogTitle>Reason for Rejection</DialogTitle>

        <DialogContent sx={{ typography: 'body2' }}>
          <UpdateBookingRefuseStatus
            handleCloseModal={() => setOpenRefuse(false)}
            // reason={reason}
            // setReason={(e) => setReasonValue(e.target.value)}
            onRefuse={onRefuse}
            loading={loadingEdit}
          />
        </DialogContent>
      </DialogAnimate>
    </>
  );
}
